<template>
    <div class="content" v-if="productData">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="breadcrumbs">
                        <li>
                            <router-link to="/">Autógumi kereső</router-link>
                        </li>
                        <template v-if="productData">
                            <li>
                                <router-link
                                    :to="'/' + getGumiLink(productData.m1nev)"
                                    >{{
                                        m1NevSwitcher(productData.m1nev)
                                    }}</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/' +
                                            getGumiLink(productData.m1nev) +
                                            '-' +
                                            productData.width +
                                            '-' +
                                            productData.profile +
                                            '-' +
                                            productData.coll
                                    "
                                    >{{ productData.width }}/{{
                                        productData.profile
                                    }}R{{ productData.coll }}
                                    {{
                                        m1NevSwitcher(productData.m1nev)
                                    }}</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="
                                        '/' +
                                            productData.emarka.toLowerCase() +
                                            '-' +
                                            getGumiLink(productData.m1nev)
                                    "
                                    >{{ productData.emarka }} -
                                    {{
                                        m1NevSwitcher(productData.m1nev)
                                    }}</router-link
                                >
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <section>
            <div class="container">
                <div class="row my-5">
                    <div class="col-8 col-md-4 order-1 order-md-1">
                        <img
                            v-lazy="
                                'http://images.akh.hu/mintazatok/' +
                                    productData.kepnev
                            "
                            class="w-50 mx-auto my-4 my-md-0 d-block"
                        />
                    </div>
                    <div class="col-md-6 my-4 my-md-0 order-3 order-md-2">
                        <h1 class="title">
                            {{ productData.cicsopnev }} <br />
                            {{ productData.cikknev }} <br />
                            {{ productData.m1nev }}
                        </h1>

                        <div class="title-small mt-4">
                            Technikai információk:
                        </div>
                        • {{ productData.cicsopnev }} -
                        {{ productData.cikknev }}
                        <br />
                        <br />
                        • <strong>SI:</strong> {{ productData.si }} <br />
                        • <strong>LI:</strong> {{ productData.li }}
                        <br />
                        <br />
                        •
                        <strong>{{ m1NevSwitcher(productData.m1nev) }}</strong>
                        <br />
                        • <strong>{{ productData.m2nev }}</strong>
                        <br />
                    </div>
                    <div class="col-4 col-md-2 order-2 order-md-3 my-4 my-md-0">
                        <img v-lazy="productData.cimke" class="w-100" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="box-sell my-4">
                            <div class="row align-items-center">
                                <div class="col-md-4 mb-4 mb-md-0">
                                    <div class="title-small">
                                        {{ productData.cicsopnev }} - <br />
                                        {{ productData.cikknev }}
                                    </div>
                                </div>
                                <div class="col-auto text-center">
                                    <div>
                                        <img
                                            src="/img/tyre_in.svg"
                                            class="small-icon"
                                            v-if="productData.tat >= 1"
                                        />
                                        <img
                                            src="/img/tyre_in.svg"
                                            class="small-icon"
                                            v-if="productData.tat >= 2"
                                        />
                                        <img
                                            src="/img/tyre_in.svg"
                                            class="small-icon"
                                            v-if="productData.tat >= 3"
                                        />
                                        <img
                                            src="/img/tyre_in.svg"
                                            class="small-icon"
                                            v-if="productData.tat >= 4"
                                        />
                                        <img
                                            src="/img/plus.svg"
                                            class="small-icon"
                                            v-if="productData.tat > 4"
                                        />
                                    </div>
                                    Raktáron
                                    <template v-if="productData.tat < 5">{{
                                        productData.tat
                                    }}</template
                                    ><template v-else>4+</template> darab
                                </div>
                                <div class="col-auto col-md my-4 my-md-0">
                                    <div class="price-box text-end">
                                        <!--<div
                                            class="price-original"
                                            v-if="
                                                productData.special_price !== 0
                                            "
                                        >
                                            Eredeti ár:
                                            {{ productData.calculated_netto }}
                                            Ft
                                        </div>-->
                                        <div
                                            class="price-actual"
                                            v-if="
                                                productData.special_price !== 0
                                            "
                                        >
                                            {{
                                                productData.calculated_special_price
                                            }}
                                            Ft
                                        </div>
                                        <div class="price-actual" v-else>
                                            {{ productData.calculated_netto }}
                                            Ft
                                        </div>
                                        <div
                                            class="price-discount"
                                            v-if="
                                                productData.special_price !== 0
                                            "
                                        >
                                            -{{
                                                productData.calculated_percent
                                            }}% <br />kedvezmény
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-md-3">
                                    <div class="d-flex align-items-center">
                                        <input
                                            type="number"
                                            min="1"
                                            :max="productData.tat"
                                            name="quantity"
                                            class="form-control list-input-padding"
                                            v-model="quantity"
                                            oninput="this.value = Math.abs(this.value)"
                                        />
                                        <div class="px-3">db</div>
                                        <button
                                            v-on:click="addCart"
                                            class="btn btn-primary list-input-padding add-to-cart d-flex align-items-center justify-content-between"
                                        >
                                            Kosárba
                                            <img
                                                src="/img/cart.svg"
                                                class="list-carticon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <b-tabs>
                            <b-tab :title="'Hasonló gumik'" class="p-4">
                                <template v-if="model && model.similar">
                                    <ProductRow
                                        v-for="(item, index) in model.similar"
                                        :no-compare="true"
                                        v-bind:key="'product-' + index"
                                        :product-data="item"
                                    ></ProductRow>
                                </template>
                            </b-tab>
                            <b-tab :title="'Vélemények'" class="p-4">
                                Nincsenek vélemények.
                            </b-tab>
                            <b-tab :title="'Felszerelés'" class="p-4">
                                Nincsenek felszerelések.
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProductRow from "@/components/ProductRow";

export default {
    name: "Product",
    components: { ProductRow },
    data() {
        return {
            model: {},
            productData: {},
            quantity: null
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        starCount: function() {
            if (this.productData.stars) {
                return this.productData.stars;
            } else {
                return 0;
            }
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    watch: {
        $route: {
            handler: function() {
                this.getSearchData();
            },
            deep: true
        }
    },
    methods: {
        addCart() {
            this.addToCart(this.productData.id, this.quantity);
        },
        getGumiLink(type) {
            switch (type) {
                case "Nyári abroncs":
                    return "nyari-gumi";
                case "Téli abroncs":
                    return "teli-gumi";
                case "Négyévszakos abroncs":
                    return "negyevszakos-gumi";
            }
        },
        getSearchData() {
            const self = this;
            self.$http
                .get(`${self.url}/products/${self.$route.params.slug}`)
                .then(response => {
                    self.model = response.data;
                    self.productData = response.data.data;
                    if (self.productData.tat > 4) {
                        self.quantity = 4;
                    } else {
                        self.quantity = self.productData.tat;
                    }

                    let pageData = {};
                    pageData.title =
                        self.productData.cicsopnev +
                        " " +
                        self.productData.cikknev +
                        " " +
                        self.productData.m1nev;
                    pageData.meta_title =
                        self.productData.cicsopnev +
                        " " +
                        self.productData.cikknev +
                        " " +
                        self.productData.m1nev;
                    pageData.meta_image =
                        "http://images.akh.hu/mintazatok/" +
                        self.productData.kepnev;
                    pageData.og_image =
                        "http://images.akh.hu/mintazatok/" +
                        self.productData.kepnev;
                    pageData.description =
                        "GUMIDISZKONT AUTÓGUMI KERESŐ GUMIABRONCS AKÁR MÁSNAPI KISZÁLLÍTÁSSAL";
                    pageData.meta_description =
                        "GUMIDISZKONT AUTÓGUMI KERESŐ GUMIABRONCS AKÁR MÁSNAPI KISZÁLLÍTÁSSAL";

                    self.metaCreation(pageData);
                });
        }
    }
};
</script>

<style scoped></style>
